import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

import { useStaticQuery, graphql } from 'gatsby';
import MenuData from "../content/Menus.json";

import Main from '../sections/main';
import About from '../sections/about';
import Steps from '../sections/steps';
import Contact from '../sections/contact';
import Clients from '../sections/clients';
import Features from '../sections/features';
// import Section from 'sections/section';



export default function IndexPage() {

  // const quer = useStaticQuery(graphql`
  //   query MyQuery {
  //     allGoogleSheetDataRow {
  //       edges {
  //         node {
  //           id
  //           section
  //           title
  //           subtitle
  //           description
  //         }
  //       }
  //     }
  //   }
  // `);

  // var dataRows = [];
  // for (var i = 0; i < quer.allGoogleSheetDataRow.edges.length; i++) {
  //   dataRows.push(quer.allGoogleSheetDataRow.edges[i].node);
  // }
  // console.log(dataRows);
  return (
    <Layout menus={MenuData}>
      <SEO />
      <Main />

      <About />

      <Steps />
      <Features />
      <Clients />
      <Contact />
    </Layout>
  );
}
