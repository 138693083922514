import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import StepsJson from "../content/Steps.json";
import StepItem from 'components/stepItem';
import WekanGIF from '../assets/images/wekan_rounded.gif'
import StreamlitGIF from '../assets/images/streamlitv2.gif'


const Step = () => {
    {/*  */ }
    const data = useStaticQuery(graphql`
        query {
            image0: file(relativePath: { eq: "iphone_woman_rounded.png" }) {
                childImageSharp {
                    fluid {
                        base64
                        aspectRatio
                        src
                        srcSet
                        sizes
                    }
                }
            }
        }
    `)
    // const content = data.allContentfulLayoutAbout.edges.find(edge => edge.node.id === contentModuleId);
    const features = StepsJson;
    // GraphQL query to our spreadsheet




    //const gif = WekanGIF ? feature.id == "s1" : null;
    return (
        <section id="services" className="services container section mx-auto ">
            <div>
                <h2 className="section__title text-center" data-sal="fade" data-sal-easing="ease-in-cubic" >Show cases of our services</h2>
                <div style={{ borderBottom: '1px', borderBottomColor: '#ccc', borderStyle: 'solid' }}></div>
                {
                    features.map(feature => {
                        if (feature.id == "s1") {
                            var gif = WekanGIF;
                        } else if (feature.id == "s2") {
                            var gif = StreamlitGIF;
                        }

                        return (
                            <StepItem feature={feature} data={data} gif={gif} key={feature.id} />
                        )
                    }
                    )
                }
            </div>
        </section>
    );
};



export default Step;