import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

//import validation from 'utils/validation';

const Contact = ({ contentModuleId }) => {

    const data = useStaticQuery(graphql`
        query {
        file(relativePath: { eq: "contact.png" }) {
            childImageSharp {
            fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
            }
            }
        }
        }
    `)
    // useEffect(() => {
    //     validation.init();
    // }, []);

    return (
        <section id="contact" className="contact  bg-gray">
            <div className="container section mx-auto px-10 py-10">
                <div className="contact__content">
                    <h2 className="section__title" data-sal="fade" data-sal-easing="ease-in-cubic">Contact us</h2>
                    <p className="mb-4 w-full md:w-3/4" data-sal="slide-up" data-sal-easing="ease-in-cubic">Interested in knowing more about our services? Write a short message below and we will get back to you asap!</p>
                    <form id="contact" name="contact" className="w-full md:w-3/4" noValidate data-sal="slide-up" data-sal-easing="ease-in-cubic" data-sal-delay="100" action="/success" method="POST" data-netlify="true" data-netlify-honeypot="bot-field">
                        <input type="hidden" name="form-name" value="contact" />
                        <p className="hidden">
                            <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                        </p>
                        <div className="input-group mb-2">
                            <label htmlFor="fullname">Name</label>
                            <input type="text" id="fullname" className="input" name="fullname" />
                        </div>
                        <div className="input-group mb-2">
                            <label htmlFor="email">E-mail</label>
                            <input type="email" id="email" className="input" name="email" />
                        </div>
                        <div className="input-group">
                            <label htmlFor="message">Message</label>
                            <textarea id="message" className="h-20" name="message"></textarea>
                        </div>
                        <div data-netlify-captcha></div>
                        <button type="submit" className="btn btn--primary mt-8">Send</button>
                    </form>
                </div>
                <div className="contact__image">
                    <div className="mx-auto" data-sal="slide-up" data-sal-delay="400" data-sal-duration="500">
                        <div className="contact__image-wrap">
                            <Img fluid={data.file.childImageSharp.fluid} alt="Contact" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Contact;