import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import MainJson from "../content/Main.json";


const Main = () => {

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "mp_green.png" }) {
        childImageSharp {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            sizes
          }
        }
      }
    }
    `)

  //const content = data.allContentfulLayoutHero.edges.find(edge => edge.node.id === contentModuleId);

  return (
    <section className="hero container section mx-auto">
      <div className="hero__tagline">
        <div className="hero__tagline-content-wrap">
          <h2 className="hero__tagline-title" data-sal="fade">{MainJson.title}</h2>
          <p className="hero__tagline-subtitle" data-sal="fade" data-sal-delay="100">{MainJson.subtitle}</p>
          <p className="hero__tagline-text" data-sal="fade" data-sal-delay="200">{MainJson.description}</p>
          <a href="#about"><button className="btn btn--primary mt-8" data-sal="fade" data-sal-delay="300">More info</button></a>
        </div>
      </div>
      <div className="hero__image">
        <img src={data.file.childImageSharp.fluid.src} className="mx-auto" alt="Hero" data-sal="slide-right" data-sal-delay="400" data-sal-duration="500" />
      </div>
    </section>
  );
};


export default Main;