import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Service from 'components/service';
import niras from 'assets/images/niras.png';

const Clients = () => {

    const data = useStaticQuery(graphql`
        query {

            niras: file(relativePath: { eq: "niras_new.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            sida: file(relativePath: { eq: "sida_new.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            ford: file(relativePath: { eq: "ford_new.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            sis: file(relativePath: { eq: "sis.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            union: file(relativePath: { eq: "uniontounion.png" }) {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    console.log('data');
    var serviceItems = []
    for (const item in data) {
        console.log(data[item])
        serviceItems.push(data[item])
    }

    return (
        <section id="clients" className="clients mt-8 py-6" >

            <div className="container mx-auto" style={{ borderBottom: '1px', borderBottomColor: '#ccc', borderStyle: 'solid' }}>
                <h3 style={{ marginTop: '20px' }}>Past clients include</h3>
            </div>
            <div className="container section mx-auto mb-8" >
                {
                    serviceItems.map(plan => (
                        <div className="clients__image">
                            <div className="mx-auto clients__image-wrap" data-sal="slide-up" data-sal-delay="200" data-sal-duration="500">
                                <Img fluid={plan.childImageSharp.fluid} />
                            </div>
                        </div>
                    ))
                }
            </div>

        </section >
    );
};
//<Service service={service} key={service.id} />


export default Clients;