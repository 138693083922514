import React from 'react';
import PropTypes from 'prop-types';
import Api from "assets/images/svg/api.svg";
import Chat from "assets/images/svg/chat.svg";
import International from "assets/images/svg/international.svg";
import Email from "assets/images/svg/mail.svg";
import Sms from "assets/images/svg/sms.svg";
import WordPdf from "assets/images/svg/word-pdf.svg";
import Signature from "assets/images/svg/signature.svg";
import Ocr from "assets/images/svg/ocr.svg";
import Ai from "assets/images/svg/robot.svg";


const Features = ({ contentModuleId }) => {

    const data = []
    return (
        <section id="features" className="features bg-gray py-10">
            <div className="container section mx-auto px-10 py-10 ">
                <div>
                    <h2 className="section__title text-center" data-sal="fade" data-sal-easing="ease-in-cubic" >Integrations & applications</h2>
                    <div className="features__items">
                        <div className="feature feature--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                            <div className="feature__image-wrap">
                                <Sms height="3em" />
                            </div>
                            <h3 className="feature__title">Text message (SMS)</h3>
                            <p>
                                {/*Skicka textmeddelanden till dina användare eller använd SMS-gränssnittet som ett alternativ till webbgränssnittet.*/}
                                Send text messages to your users or use the SMS interface as an alternative to the web interface.
                            </p>
                        </div>
                        <div className="feature feature--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                            <div className="feature__image-wrap">
                                <Email height="3em" />
                            </div>
                            <h3 className="feature__title">E-mail</h3>
                            <p>
                                {/*Skicka och ta emot e-postmeddelanden i dina intervjuer. (Du kan också skicka fax)*/}
                                Send and receive e-mails in your interviews. (You can send faxes, too.)
                            </p>
                        </div>
                        <div className="feature feature--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                            <div className="feature__image-wrap">
                                <Ocr height="3em" />
                            </div>
                            <h3 className="feature__title">OCR</h3>
                            <p>
                                {/*Använd optisk teckenigenkänning för att bearbeta bilder som laddats upp av användaren.*/}
                                Use optical character recogntion to process images uploaded by the user.
                            </p>
                        </div>
                        <div className="feature feature--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                            <div className="feature__image-wrap">
                                <WordPdf height="3em" />
                            </div>
                            <h3 className="feature__title">WYSIWYG</h3>
                            <p>
                                {/*Komponera dina mallar i .docx (med hjälp av ett Word-tillägg) eller PDF-filer.*/}
                                Compose your templates in .docx (with help of a Word Add-in) or .pdf files.
                            </p>
                        </div>
                        <div className="feature feature--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                            <div className="feature__image-wrap">
                                <Signature height="3em" />
                            </div>
                            <h3 className="feature__title">Signatures</h3>
                            <p>
                                {/*Samla pekskärmsunderskrifter och bädda in dem i dokument.*/}
                                Gather touchscreen signatures and embed them in documents.

                            </p>
                        </div>
                        <div className="feature feature--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                            <div className="feature__image-wrap">
                                <Ai height="3em" />
                            </div>
                            <h3 className="feature__title">AI</h3>
                            <p>
                                {/*Använd maskininlärning för att bearbeta användarinmatning.*/}
                            Use machine learning to process user input.</p>
                        </div>
                        <div className="feature feature--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                            <div className="feature__image-wrap">
                                <International height="3em" />
                            </div>
                            <h3 className="feature__title">Multi-language</h3>
                            <p>
                                {/*Erbjud intervjuer på flera språk.*/}
                                    Offer interviews in multiple languages.</p>
                        </div>
                        <div className="feature feature--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                            <div className="feature__image-wrap">
                                <Api height="3em" />
                            </div>
                            <h3 className="feature__title">API</h3>
                            <p>
                                {/*Integrera med tredjepartsapplikationer med API, eller skicka dina intervjuer och mata ut utdata med kod.*/}
                                    Integrate with third-party applications using the API, or send your interviews input and extract output using code.

                            </p>
                        </div>
                        <div className="feature feature--left" data-sal="fade" data-sal-easing="ease-in-cubic" data-sal-duration="400">
                            <div className="feature__image-wrap">
                                <Chat height="3em" />
                            </div>
                            <h3 className="feature__title">Chat function</h3>
                            <p>
                                {/*Hjälp användare i realtid med livechatt, skärmdelning och fjärrskärmskontroll.*/}
                                        Assist users in real time with live chat, screen sharing, and remote screen control.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

Features.propTypes = {
    contentModuleId: PropTypes.string.isRequired
}

export default Features;