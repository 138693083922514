import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import AboutJson from "../content/About.json";
import AboutItem from 'components/aboutItem';
import WomanPNG from '../assets/images/woman.png'

const StepItem = ({ feature, data, gif }) => {
    const fil = "about.png"
    console.log(feature.image)
    const sectionClassname = feature.bg + " about-us"
    const image = gif ? gif : data[feature.image].childImageSharp.fluid.src;
    return (
        <div className="container section mx-auto">
            <div className={feature.position.text} style={{ paddingBottom: '1.5rem' }}>
                <h2 className="section__title" data-sal="fade" data-sal-easing="ease-in-cubic">{feature.title}</h2>
                <p data-sal="slide-up" data-sal-easing="ease-in-cubic" data-sal-delay="100">{feature.description}</p>
            </div>
            <div className={feature.position.image}>
                <div className="steps__image-wrap" data-sal="slide-up" data-sal-delay="200" data-sal-duration="500">
                    <img src={image} />
                </div>
            </div>
        </div>
    );
};


//

export default StepItem;