import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import AboutJson from "../content/About.json";
import AboutItem from 'components/aboutItem';

const About = () => {
    {/* 
        const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutAbout {
                edges {
                    node {
                        id
                        heading
                        description {
                            description
                        }
                        featureItem {
                            id
                            title
                            icon
                            description {
                                description
                            }
                        }
                        image {
                            fluid(quality:100) {
                                ...GatsbyContentfulFluid
                            }
                        }
                    }
                }
            }
        }
    `); */}
    const data = useStaticQuery(graphql`
        query {
        file(relativePath: { eq: "about_turkos.png" }) {
            childImageSharp {
            fluid {
                base64
                aspectRatio
                src
                srcSet
                sizes
            }
            }
        }
        }
    `)
    // const content = data.allContentfulLayoutAbout.edges.find(edge => edge.node.id === contentModuleId);
    const featureItem = AboutJson;
    console.log(featureItem.length);
    return (
        <section id="about" className="about-us bg-gray">
            <div className="container section mx-auto">
                <div className="about-us__content">
                    <h2 className="section__title" data-sal="fade" data-sal-easing="ease-in-cubic">What we offer.</h2>
                    <p data-sal="slide-up" data-sal-easing="ease-in-cubic" data-sal-delay="100">We simplify all types of data collection for your clients. Our digital solutions can handle the majority of all available media formats, documents, and geographical data. Collected data is furthermore easily accessible and managed in our easy-to-work case management system. It is also possible to integrate our solutions into an existing management system.
                    </p>
                    {
                        featureItem.length > 0 &&
                        <ul className="mt-10 md:ml-8">
                            {
                                featureItem.map(feature => (
                                    <AboutItem feature={feature} key={feature.id} />
                                ))
                            }
                        </ul>
                    }
                </div>
                <div className="about-us__content">
                    <div className="mx-auto about-us__image-wrap" data-sal="slide-up" data-sal-delay="200" data-sal-duration="500">
                        <Img fluid={data.file.childImageSharp.fluid} />
                    </div>
                </div>
            </div>
        </section>
    );
};



export default About;